import firebasec from "firebase/app";
import "firebase/database";

const firebaseConfig = {
  apiKey: process.env.apiKey,
  authDomain: "registerform-liquidator.firebaseapp.com",
  projectId: "registerform-liquidator",
  storageBucket: "registerform-liquidator.appspot.com",
  messagingSenderId: process.env.messagingSenderId,
  appId: process.env.appId,
  measurementId: process.env.measurementId,
};

firebasec.initializeApp(firebaseConfig);

export default firebasec;
