const entityTypeIndicator = [
  "LP",
  "LL",
  "FC",
  "PF",
  "RF",
  "MQ",
  "MM",
  "NB",
  "CC",
  "CS",
  "MB",
  "FM",
  "GS",
  "GA",
  "GB",
  "DP",
  "CP",
  "NR",
  "CM",
  "CD",
  "MD",
  "HS",
  "VH",
  "CH",
  "MH",
  "CL",
  "XL",
  "CX",
  "RP",
  "TU",
  "TC",
  "FB",
  "FN",
  "PA",
  "PB",
  "SS",
  "MC",
  "SM",
];

function ValidateUEN(uen) {
  if (uen.length < 9 || uen.length > 10) {
    return false;
  }
  uen = uen.toUpperCase();
  var uenStrArray = uen.split("");

  if (uenStrArray.length === 9) {
    if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
      //   error = "UEN is not valid, last character is not an alphabet";
      return false;
    }
    for (var i = 0; i < uenStrArray.length - 1; i++) {
      // check that first 8 letters are all numbers
      if (isNaN(uenStrArray[i])) {
        // error = "UEN is not valid, there are non-numbers in 1st to 8th letters";
        return false;
      }
    }
  } else if (uenStrArray.length === 10) {
    // check that last character is a letter
    if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
      //   error = "UEN is not valid, last character is not an alphabet";
      return false;
    }

    if (
      !isNaN(uenStrArray[0]) &&
      !isNaN(uenStrArray[1]) &&
      !isNaN(uenStrArray[2]) &&
      !isNaN(uenStrArray[3])
    ) {
      if (
        !isNaN(uenStrArray[4]) &&
        !isNaN(uenStrArray[5]) &&
        !isNaN(uenStrArray[6]) &&
        !isNaN(uenStrArray[7]) &&
        !isNaN(uenStrArray[8])
      ) {
        // error = "asdsa"
      } else {
        // error = "UEN is not valid, there are non-numbers in 5th to 9th letters";
        return false;
      }
    } else {
      // check that 1st letter is either T or S or R
      if (
        uenStrArray[0] !== "T" &&
        uenStrArray[0] !== "S" &&
        uenStrArray[0] !== "R"
      ) {
        // error = "UEN 1st letter is incorrect";
        return false;
      }

      if (isNaN(uenStrArray[1]) || isNaN(uenStrArray[2])) {
        // error = "UEN 2nd and 3rd letter is incorrect";
        return false;
      }

      if (!isNaN(uenStrArray[3])) {
        // error = "UEN 4th letter is not an alphabet";
        return false;
      }

      var entityTypeMatch = false,
        entityType = String(uenStrArray[3]) + String(uenStrArray[4]);
      for (var it = 0; it < entityTypeIndicator.length; it++) {
        if (String(entityTypeIndicator[it]) === String(entityType)) {
          entityTypeMatch = true;
        }
      }
      if (!entityTypeMatch) {
        // error = "UEN entity-type indicator is invalid";
        return false;
      }
      if (
        isNaN(uenStrArray[5]) ||
        isNaN(uenStrArray[6]) ||
        isNaN(uenStrArray[7]) ||
        isNaN(uenStrArray[8])
      ) {
        // error = "UEN 2nd and 3rd letter is incorrect";
        return false;
      }
    }
  }
  return true;
}

export default ValidateUEN;
