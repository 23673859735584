import React from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import firebase from "./util/config";
import ValidateNRIC from "./ValidateNRIC";
import ValidateUEN from "./ValidateUEN";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AlertMassage from "./AlertMessage";
import { format } from "date-fns";

var date = new Date();
var formattedDate = format(date, " do MMMM yyyy H:mma");
var ref = firebase.database().ref("Registration");

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = Yup.object({
  coName: Yup.string().min(5, "Company Name too short").required("Required!"),
  coAdd: Yup.string().min(10, "Address too short").required("Required!"),
  name: Yup.string().required("Required!"),
  phone: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Phone number must be only digits")
    .min(8, "Not a valid phone number"),
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  debtAmt: Yup.mixed()
    .required("Amount is required")
    .test("type", "Amount must be a number", (value) => !isNaN(value))
    .test("validDollar", "Amount must be a valid dollar amount", (value) =>
      /^\d*.?\d{0,2}$/.test(value)
    ),
  queries: Yup.string().max(125, "Please shorten your question"),
});

const validate = (values, props /* only available when using withFormik */) => {
  const errors = {};
  if (!values.UEN) {
    errors.UEN = "Required";
  } else if (!ValidateUEN(values.UEN)) {
    errors.UEN = "Invalid UEN!";
  }
  if (!values.NRIC) {
    errors.NRIC = "Required";
  } else if (!ValidateNRIC(values.NRIC)) {
    errors.NRIC = "Invalid email NRIC!";
  }
  return errors;
};

const WithMaterialUI = () => {
  const formik = useFormik({
    initialValues: {
      coName: "",
      UEN: "",
      coAdd: "",
      name: "",
      NRIC: "",
      phone: "",
      email: "",
      debtAmt: "",
      queries: "",
    },
    validationSchema,
    validate,

    onSubmit: (values) => {
      values.UEN = values.UEN.toUpperCase();
      values.NRIC = values.NRIC.toUpperCase();
      let finalValue = {
        ...values,
        submittedAt: formattedDate,
      };
      ref
        .orderByChild("UEN")
        .equalTo(values.UEN)
        .once("value", (snapshot) => {
          const userData = snapshot.val();
          if (userData) {
            setStatusBase({
              msg:
                "Duplicate form submission, your company has a pending submission!",
              sType: "error",
              key: Math.random(),
            });
          } else {
            ref.push(finalValue);
            setStatusBase({
              msg:
                "Form submitted sucessfully, please wait for email confirmation!",
              sType: "success",
              key: Math.random(),
            });
          }
        });
    },
  });
  const classes = useStyles();
  const [status, setStatusBase] = React.useState("");
  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            LI JIE CONSTRUCTION PTE. LTD. (In Creditors' voluntary Liquidation)
          </Typography>
          <ul>
            <li>
              This form is to register interest for the meeting on the 19th
              February 2021.
            </li>
            <li>Registration will be closed on the 17th February 2021. </li>
            <li>Only one submission per company is allowed.</li>
          </ul>
          {status ? (
            <AlertMassage
              key={status.key}
              message={status.msg}
              sType={status.sType}
            />
          ) : null}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="coName"
                name="coName"
                label="Company Name"
                value={formik.values.coName}
                onChange={formik.handleChange}
                error={formik.touched.coName && Boolean(formik.errors.coName)}
                helperText={formik.touched.coName && formik.errors.coName}
              />
            </Grid>
          </Grid>

          <TextField
            fullWidth
            id="UEN"
            name="UEN"
            label="Company UEN"
            value={formik.values.UEN}
            onChange={formik.handleChange}
            error={formik.touched.UEN && Boolean(formik.errors.UEN)}
            helperText={formik.touched.UEN && formik.errors.UEN}
          />
          <TextField
            fullWidth
            id="coAdd"
            name="coAdd"
            label="Company Registered Address"
            value={formik.values.coAdd}
            onChange={formik.handleChange}
            error={formik.touched.coAdd && Boolean(formik.errors.coAdd)}
            helperText={formik.touched.coAdd && formik.errors.coAdd}
          />
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Name of person attending the meeting"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            fullWidth
            id="NRIC"
            name="NRIC"
            label="NRIC of person attending the meeting"
            value={formik.values.NRIC}
            onChange={formik.handleChange}
            error={formik.touched.NRIC && Boolean(formik.errors.NRIC)}
            helperText={formik.touched.NRIC && formik.errors.NRIC}
          />

          <TextField
            fullWidth
            id="phone"
            name="phone"
            type="tel"
            label="Phone Number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />

          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            fullWidth
            id="debtAmt"
            name="debtAmt"
            label="Debt amount (SGD $)"
            type="number"
            value={formik.values.debtAmt}
            onChange={formik.handleChange}
            error={formik.touched.debtAmt && Boolean(formik.errors.debtAmt)}
            helperText={formik.touched.debtAmt && formik.errors.debtAmt}
          />

          <TextField
            fullWidth
            id="queries"
            name="queries"
            label="Queries to Liquidator"
            value={formik.values.queries}
            onChange={formik.handleChange}
            error={formik.touched.queries && Boolean(formik.errors.queries)}
            helperText={formik.touched.queries && formik.errors.queries}
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting}
          >
            Submit
          </Button>
        </form>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "} {new Date().getFullYear()}{" "}
      <Link color="inherit" href="https://synosol.com/">
        Synergy Office Solutions Pte Ltd
      </Link>
      {"."}
    </Typography>
  );
}

ReactDOM.render(<WithMaterialUI />, document.getElementById("root"));
